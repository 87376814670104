import Vue from 'vue/dist/vue.esm.js';
import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm';
import { bg, en } from 'vuejs-datepicker/dist/locale'
import count from "@ckeditor/ckeditor5-utils/src/count";

Vue.component('datepicker', Datepicker);

Vue.component('mystery-box-checkout', {
    template: `#mystery-box-checkout`,
    delimiters: ['[[', ']]'],
    props: {
        mysteryBox: {
            required: true,
            type: Object,
        },
        locale: {
            required: true,
            type: String,
        },
        currency: {
            required: true,
            type: String,
        },

        periods: {
            required: true,
            type: Array,
        },
        giftPeriods: {
            required: true,
            type: Array,
        },
        paymentMethods: {
            required: true,
            type: Array,
        },
        activeDiscounts: {
            required: true,
            type: Array,
        },
        fetchOfficesUrl: {
            required: true,
            type: String,
        },

        hasUser: {
            required: true,
            type: Number,
        },
        userCards: {
            required: false,
            type: Array,
        }
    },

    mounted() {

        this.initIsGift();
        this.initHasCard();

        if (parseInt(this.$refs.selectedPaymentMethod.dataset.val)) {
            this.selectPaymentMethod(this.paymentMethods.filter(d => d.id === parseInt(this.$refs.selectedPaymentMethod.dataset.val))[0]);
        }
        if (this.$refs.selectedDate.dataset.val) {
            this.selectDate(this.$refs.selectedDate.dataset.val);
        }


        if (parseInt(this.$refs.period.dataset.val)) {
            if (this.isGift) {
                this.selectGiftPeriod(this.giftPeriods.filter(d => d.value === parseInt(this.$refs.period.dataset.val))[0]);
            } else {

                this.selectPeriod(this.periods.filter(d => d.value === parseInt(this.$refs.period.dataset.val) && d.type == this.$refs.periodType.dataset.val)[0]);

            }

        }

        if (parseInt(this.$refs.selectedCard.dataset.val)) {
            this.selectCard(this.userCards.filter(d => d.id === parseInt(this.$refs.selectedCard.dataset.val))[0]);
        }

        if (this.$refs.cityField && this.$refs.cityField.value) {
            this.selectedCity = this.$refs.cityField.value;
        }

        EventManager.listen('load-stimulus', this.loadStimulus);

    },


    data() {
        return {
            selectedPeriod: this.periods.filter(a => a.selected === true)[0],
            selectedGiftPeriod: this.giftPeriods.filter(a => a.selected === true)[0],
            selectedCity: null,
            selectedPaymentMethod: null,
            selectedCard: null,
            selectedDate: null,
            isGift: 0,
            hasCard: 0,
            period: this.periods.filter(a => a.selected === true)[0].value,
            periodType: this.periods.filter(a => a.selected === true)[0].type,
            total: this.mysteryBox.price,
            en: en,
            bg: bg,
            disabledDates: {
                to: new Date((new Date()).setDate(new Date().getDate() + 2)),
                days: [6, 0],
            },
            subscriptionFixedType: 0,
            subscriptionFreeType: 0,
            openDate: function () {
                let counter = 3;

                if ((new Date()).getDay() === 3)
                    counter = 5;
                else if ((new Date()).getDay() === 4)
                    counter = 4;

                return new Date((new Date()).setDate(new Date().getDate() + counter));
            },
        }
    },

    methods: {
        loadStimulus() {
            let eventStep1 = {
                event: 'ecc.mystery-box-checkout',
                checkout_type: 'Mystery box',
                step: 1,
                cart: this.mysteryBox
            }

            EventManager.fire('gtm-event', eventStep1);

            this.checkoutStep2(this.isGift);

            this.subscriptionFreeType = 1;
            this.subscriptionFixedType = 0;

        },
        checkoutStep2(isGift) {
            let isGiftString = 'not a gift';
            if (isGift === true) {
                isGiftString = 'is a gift';
            }

            this.initStep(1, isGiftString);
        },
        checkoutStep3NotGift(period) {
            let periodText = 'monthly';
            if (period.value === 2) {
                periodText = 'every other month';
            } else if (period.value === 3) {
                periodText = 'once per 3 months';
            }

            this.initStep(3, periodText);
        },
        checkoutStep3Gift(period) {
            let periodText = '1 month';
            if (period.value > 1) {
                periodText = period.value + ' months';
            }

            this.initStep(3, periodText);
        },
        changeUserData() {
            this.initStep(5, "customer details");
        },
        changeReceiverData() {
            this.initStep(5, "customer details - gift");
        },
        agreeTerms() {
            this.initStep(7);
        },
        initStep(step, data = null) {
            let eventStep = {
                event: 'ecc.mystery-box-checkout',
                action: step,
            }

            if (data != null) {
                eventStep.eventData = data;
            }

            EventManager.fire('gtm-event', eventStep);
        },
        documentOnClick(e) {
            this.initStep(4);
            if (!this.$refs.datepicker.$el.contains(e.target)) {
                this.closeCalendar();
            }

        },
        selectDate(date) {
            this.selectedDate = date;
        },
        openCalendar(e) {
            e.stopPropagation();
            this.$refs.datepicker.showCalendar();
            document.addEventListener("click", this.documentOnClick);
        },
        closeCalendar() {
            this.$refs.datepicker.close();
            document.removeEventListener("click", this.documentOnClick);
        },
        selectPeriod(period) {
            this.selectedPeriod = period;
            this.period = period.value;
            this.periodType = period.type;
            this.checkoutStep3NotGift(period);
            this.subscriptionFreeType = 0;
            this.subscriptionFixedType = 1;
        },
        selectGiftPeriod(period) {
            this.selectedGiftPeriod = period;
            this.period = period.value;
            this.periodType = period.type;
            this.checkoutStep3Gift(period);
        },
        initIsGift() {
            let value;
            if (this.validateRef("isGift")) {
                if (isNaN(parseInt(this.$refs.isGift.dataset.val))) {
                    value = null;
                } else {
                    value = parseInt(this.$refs.isGift.dataset.val);
                }
            }
            this.isGift = value;
        },
        initHasCard() {
            let value;
            if (this.validateRef("hasCard")) {
                if (isNaN(parseInt(this.$refs.hasCard.dataset.val))) {
                    value = null;
                } else {
                    value = parseInt(this.$refs.hasCard.dataset.val);
                }
            }
            this.hasCard = value;
        },

        validateRef(ref) {
            if (this.$refs[ref] !== undefined) {
                return true;
            }
            return false;
        },


        removeOptions(selectElement, clearSelected = false) {
            if (!selectElement) {
                return;
            }

            let i, Length = selectElement.options.length - 1;
            for (i = Length; i >= 1; i--) {

                if (selectElement.options[i].selected === true && !clearSelected) {
                    continue;
                }

                selectElement.remove(i);

            }
        },


        async postData(url = '', data = {}) {
            // Default options are marked with *
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
            return response.json(); // parses JSON response into native JavaScript objects
        },

        selectPaymentMethod(paymentMethod) {

            if (paymentMethod ===
                this.selectedPaymentMethod) {
                return;
            }
            this.selectedPaymentMethod = paymentMethod;
            this.selectedCard = null;

            if (paymentMethod.method === 'credit_card' && this.userCards.length > 1) {
                this.selectCard(this.userCards.filter(d => d.isPrimary === true)[0]);
            }

            this.initStep(6, paymentMethod.method);
        },

        selectCard(card) {
            if (card === this.selectedCard) {
                return;
            }
            this.selectedCard = card;
        },
        cityChanged(data) {
            this.selectedCity = data;
            this.tryToPopulateOffices(true);
        },

        toggleIsGift() {
            let select2 = $(this.$refs.cityField);
            select2.select2('destroy');

            this.isGift = !this.isGift & 1;
            if (this.isGift) {
                this.period = this.selectedGiftPeriod.value;
                this.periodType = this.selectedGiftPeriod.type;
            } else {
                this.period = this.selectedPeriod.value;
                this.periodType = this.selectedPeriod.type;
            }

            this.checkoutStep2(this.isGift);

        },

        toggleHasCard() {
            this.hasCard = !this.hasCard & 1;
        },

        preselectCity(city) {

            let select2 = $(this.$refs.cityField);
            let newOption = new Option(city.name, city.id, true, true);

            select2.append(newOption).trigger('change');
        },

        formattedPrice(price) {
            return new Intl.NumberFormat(this.locale, { style: 'currency', currency: this.currency }).format(price);
        },


        periodsByType(type) {
            return this.periods.filter(p => p.type == type);
        },

        isPeriodInActiveDiscount(period) {

            if (period.type == 'recurring') {
                return false;
            }
            return this.activeDiscounts.filter(d => d.x == period.value).length > 0;
        },

        activeDiscountPromo(period) {
            const discount = this.activeDiscounts.find(d => d.x == period.value);
            if (discount) {
                return discount.y !== undefined ? discount.y : discount.price;
            }
        },
        findDiscountType(period) {
            const discount = this.activeDiscounts.find(d => d.x == period.value);

            if (discount) {
                if (discount.y !== undefined) {
                    return 'BuyXGetYDiscount';
                } else if (discount.price !== undefined) {
                    return 'BuyXForFixedPriceDiscount';
                }
            }
        },
        isIOS() {
            const ua = navigator.userAgent;
            // Check for macOS or iOS
            const isMacOrIOS = /Macintosh|Mac OS X|iPad|iPhone|iPod/.test(ua);
            // Check if the browser is Safari
            const isSafari = /Safari/.test(ua) && !/Chrome|CriOS|FxiOS|OPR|Edg/.test(ua);
            return isMacOrIOS && isSafari;

            // const ua = navigator.userAgent;
            // const isMacSafari = /Macintosh/.test(ua) && /Safari/.test(ua) && !/Chrome/.test(ua);
            // const isIOS = /iPad|iPhone|iPod/.test(ua) && !window.MSStream && /Safari/.test(ua);
            //
            // return isMacSafari || isIOS;
        },
        getAvailableMethods() {
            let methods = this.paymentMethods;
            if (this.isGift) {
                methods = this.paymentMethods.filter(pm => pm.isAvailableForGift === true);
            } else {

                if (this.selectedPeriod.type == 'recurring') {
                    methods = this.paymentMethods.filter(pm => pm.method !== 'bank_transfer' && pm.method !== 'credit_card' && pm.method !== 'apple_pay');
                } else {
                    methods = this.paymentMethods.filter(pm => pm.method !== 'recurring' && pm.isAvailableForGift === true);
                }

            }

            let isIos = this.isIOS();

            if (!isIos) {
                methods = methods.filter(pm => pm.onlyIos === false);
            }

            return methods;
        },

        formattedDate(datetimeString) {

            let date = new Date(datetimeString);
            return new Intl.DateTimeFormat(this.locale, {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
                weekday: 'long'
            }).format(date);
        },


        calcTotal(includeDelivery) {

            let total = this.cart.total;
            if (this.selectedPackage) {
                total += this.selectedPackage.price;
            }
            if (includeDelivery) {
                total += this.deliveryPrice ?? 0;
            }
            return total;
        },

        selectPeriodType(fixed, open) {
            if (this.subscriptionFreeType === fixed && this.subscriptionFixedType === open)
                return;

            this.subscriptionFreeType = fixed;
            this.subscriptionFixedType = open;

            if (open === 0) {
                this.selectedPeriod = this.periods.filter(a => a.selected === true)[0];
            } else {
                this.selectedPeriod = this.periods.filter(p => p.type == 'fixed')[2];
            }

        }

    },

    computed: {
        paymentMethodComputed() {
            if (this.selectedPaymentMethod) {
                return this.selectedPaymentMethod.id;
            }
            return null;
        },
        formattedTotal() {
            if (this.isGift) {
                const discount = this.activeDiscountPromo(this.selectedGiftPeriod);
                const type = this.findDiscountType(this.selectedGiftPeriod);

                // If the discount has a 'price', return it directly
                if (type !== undefined && discount !== undefined) {
                    if (type == 'BuyXGetYDiscount') {
                        return this.formattedPrice(this.total * this.selectedGiftPeriod.value)
                    }

                    else if (type == 'BuyXForFixedPriceDiscount') {
                        return this.formattedPrice(discount);
                    }
                }
                // Default: return the formatted total without discounts
                return this.formattedPrice(this.total * this.selectedGiftPeriod.value)

            }
            if (this.selectedPeriod.type == 'fixed') {
                const discount = this.activeDiscountPromo(this.selectedPeriod);
                const type = this.findDiscountType(this.selectedPeriod);

                // If the discount has a 'price', return it directly
                if (type !== undefined && discount !== undefined) {

                    if (type == 'BuyXGetYDiscount') {
                        return this.formattedPrice(this.total * this.selectedPeriod.value)
                    }

                    else if (type == 'BuyXForFixedPriceDiscount') {
                        return this.formattedPrice(discount);
                    }
                }
                // Default: return the formatted total without discounts
                return this.formattedPrice(this.total * this.selectedPeriod.value)

            }
            // Default: return the formatted total without discounts
            return this.formattedPrice(this.total);
        },
    }
});