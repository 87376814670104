import {Controller} from "stimulus"


export default class extends Controller {
    impressionData;
    promotionData;

    connect() {
        if (!window.dataLayer) {
            return
        }
        this.object = this.element.getAttribute('data-object');
        this.event = this.element.getAttribute("data-event");
        this.action = this.element.getAttribute("data-action");
        this.eventData = this.element.getAttribute("data-data");
        this.uniqueEventId = this.element.getAttribute("data-unique-event-id") ?? null;

        this.impressionData = [];
        this.promotionData = [];

        if (this.event && this.action) {
            this.prepareEvent();
        } else {
            this.prepareSimpleEvent();
        }
    }


    prepareEvent() {
        if (this.event === 'view_item') {
            this.viewItem(); //new
        } else if (this.event === 'view_cart') {
            this.viewCart();//new
        } else if (this.event === 'begin_checkout' && this.action === 'begin_checkout_product') {
            this.beginCheckoutProduct(); //new
        } else if (this.event === 'begin_checkout' && this.action === 'begin_checkout_mystery_box') {
            this.beginCheckoutMysteryBox();//new
        } else if (this.event === 'purchase') {
            this.purchaseProducts();//new
        } else if (this.event === 'eec.lead') {
            this.eecLead();
        }
    }

    onGtmEvent(data) {
        this.event = data.event;
        this.action = data.action;
        this.eventData = data.eventData;
        this.uniqueEventId = data.unique_event_id;
        this.cart = data.cart;

        // this.checkoutRowId = data.rowId;
        // this.checkoutQuantity = data.quantity;
        if (this.event === 'add_to_cart') {
            this.addToCart();
        } else if (this.event === 'max_quantity_reached') {
            this.maxQuantityReached();
        } else if (this.event === 'remove_from_cart') {
            this.removeFromCart();
        } else if (this.event === 'eec.checkout') {
            // this.eecCheckout();
        } else if (this.event == 'ecc.mystery-box-checkout') {
            // this.eecMysteryBoxCheckout(data);
        }
    }

    buildMysteryBoxDataLayer(data, event, quantity = 1) {
        let layer = {
            event: event,
            currency: 'BGN', // валута
            value: parseFloat(data.price), //стойност на продукта, подават се динамични стойности
            items: [
                {
                    item_id: data.id, //ид на селектирания продукт, подават се динамични стойности
                    item_name: data.name, //име на  селектирания продукт,подават се динамични стойности
                    item_category: "MysteryBox", //категория на продукта, подават се динамични стойности
                    discount: 0, //стойност на отстъпка, подават се динамични стойности
                    price: data.price, //стойност на продукта, подават се динамични стойности
                    quantity: quantity //количество
                }
            ]
        }

        return layer;
    }

    buildMaxQuantityReachedDataLayer(data, event) {
        let layer = {
            event: event,
            SKU: data.sku
        }

        return layer;
    }

    buildCartDataLayer(data, event) {
        let items = [];

        data.rows.forEach(cartRow => {
            items.push(
                {
                    item_id: cartRow.productId, //ид на селектирания продукт, подават се динамични стойности
                    item_name: cartRow.name, //име на  селектирания продукт,подават се динамични стойности
                    item_category: cartRow.categoryNameGTM, //категория на продукта, подават се динамични стойности
                    item_category2: cartRow.categoryNameGTM2, //категория на продукта, подават се динамични стойности
                    discount: (cartRow.originalPrice - cartRow.price), //стойност на отстъпка, подават се динамични стойности
                    price: parseFloat(cartRow.price), //стойност на продукта, подават се динамични стойности
                    quantity: cartRow.quantity //количество
                }
            );
        });
        let layer = {
            event: event,
            currency: 'BGN', // валута
            value: parseFloat(data.totalPriceAfterDiscount), //стойност на продукта, подават се динамични стойности
            items: items
        }

        return layer;
    }

    purchaseProducts() {
        let layer = JSON.parse(this.eventData);
        layer["event"] = this.event;

        // console.log("GTM", layer);

        this.pushData(layer);
    }

    beginCheckoutProduct() {
        let data = JSON.parse(this.eventData);
        let layer = this.buildCartDataLayer(data, this.event);

        // console.log("GTM", layer);

        this.pushData(layer);
    }

    beginCheckoutMysteryBox() {
        let data = JSON.parse(this.eventData);

        let layer = this.buildMysteryBoxDataLayer(data, this.event);

        // console.log("GTM", layer);

        this.pushData(layer);
    }

    viewItem() {
        let data = JSON.parse(this.eventData);
        let layer = this.buildProductDataLayer(data, this.event);

        // console.log("GTM", layer);

        this.pushData(layer);
    }

    viewCart() {
        let data = JSON.parse(this.eventData);
        let layer = this.buildCartDataLayer(data, this.event);

        // console.log("GTM", layer);

        this.pushData(layer);
    }

    addToWishlist(data) {
        let layer = this.buildProductDataLayer(JSON.parse(data), 'add_to_wishlist');

        // console.log("GTM", layer);

        this.pushData(layer);
    }

    addToCart() {
        let layer = this.buildProductDataLayer(this.eventData, this.event, this.eventData.quantity);

        // console.log("GTM", layer);

        this.pushData(layer);
    }

    maxQuantityReached() {
        let layer = this.buildMaxQuantityReachedDataLayer(this.eventData, this.event);

        this.pushData(layer);
    }

    buildProductDataLayer(data, event, quantity = 1) {
        let layer = {
            event: event,
            currency: 'BGN', // валута
            value: parseFloat(data.sale_price) * quantity, //стойност на продукта, подават се динамични стойности
            items: [
                {
                    item_id: data.id, //ид на селектирания продукт, подават се динамични стойности
                    item_name: data.name, //име на  селектирания продукт,подават се динамични стойности
                    item_category: data.categoryNameGTM, //категория на продукта, подават се динамични стойности
                    item_category2: data.categoryNameGTM2, //категория на продукта, подават се динамични стойности
                    discount: (parseFloat(data.price) - data.sale_price), //стойност на отстъпка, подават се динамични стойности
                    price: parseFloat(data.sale_price), //стойност на продукта, подават се динамични стойности
                    quantity: quantity //количество
                }
            ]
        }

        return layer;
    }


    removeFromCart() {
        let layer = this.buildProductDataLayer(this.eventData, this.event, this.eventData.quantity);

        // console.log("GTM", layer);

        this.pushData(layer);
    }


    //ENDNEW

    prepareSimpleEvent() {

        if (this.eventData) {
            let layer = {
                [this.object]: JSON.parse(this.eventData)
            }
            // this.pushData(layer);
        }
    }

    initialize() {

        if (!window.dataLayer) {
            return;
        }
        this.dataLayer = window.dataLayer;
        this.isMain = this.element.getAttribute('data-main');

        if (this.isMain) {
            EventManager.listen('gtm-event', payload => {
                this.onGtmEvent(payload);
            });

            //NEW
            EventManager.listen('favorite-added', payload => {
                // console.log(payload);
                this.addToWishlist(payload);
            });

            //END NEW


            // //Impressions
            // EventManager.listen('impression-appear', payload => {
            //     this.onImpressionEvent(payload);
            // });
            // EventManager.listen('impression-click', payload => {
            //     this.onImpressionClickEvent(payload);
            // });
            //
            // //PageView
            // EventManager.listen('page-view', payload => {
            //     this.onPageViewEvent(payload);
            // });
            //
            // //Promotions
            // EventManager.listen('promotion-appear', payload => {
            //     this.onPromotionEvent(payload);
            // });
            // EventManager.listen('promotion-click', payload => {
            //     this.onPromotionClickEvent(payload);
            // });
            //
            // //Mystery-box checkout 0.1 0.2 0.3 0.4
            // EventManager.listen('load-mystery-box', payload => {
            //     this.onLoadMysteryBox(payload);
            // });
            // EventManager.listen('subscribe-mystery-box', payload => {
            //     this.onSubscribeMysteryBox(payload);
            // });
            // EventManager.listen('login-mystery-box', payload => {
            //     this.onLoginMysteryBox(payload);
            // });
            // EventManager.listen('checkout-mystery-box', payload => {
            //     this.onCheckoutMysteryBox(payload);
            // });
            //
            // //Event checkout
            // EventManager.listen('load-event-checkout', payload => {
            //     this.onLoadEventCheckout(payload);
            // });
            // EventManager.listen('load-event-checkout-client-data', payload => {
            //     this.initSimpleStep(2, 'Events')
            // });
            // EventManager.listen('load-event-checkout-payment-method', payload => {
            //     this.initSimpleStep(3, 'Events')
            // });
            // EventManager.listen('load-event-checkout-terms', payload => {
            //     this.initSimpleStep(4, 'Events')
            // });
        }
        EventManager.fire('load-stimulus', null);

        // setInterval(() => this.sendImpressions(), 500)
        // setInterval(() => this.sendPromotions(), 500)
    }

    onLoadEventCheckout(data) {
        let productsData = [];
        productsData[0] = {
            'name': data.name,
            'id': data.id,
        };

        let layer = {
            event: 'ecc.event-checkout',
            ecommerce: {
                checkout: {
                    actionField: {
                        step: 1
                    },
                    products: productsData,
                    checkout_type: 'Events'
                }
            }
        }

        this.pushData(layer);
    }

    initSimpleStep(step, checkoutType) {
        let layer = {
            event: 'ecc.event-checkout',
            ecommerce: {
                checkout: {
                    actionField: {
                        step: step
                    },
                    checkout_type: checkoutType
                }
            }
        }

        this.pushData(layer);
    }

    onLoadMysteryBox() {
        let layer = {
            event: 'mystery-box-step-0-1'
        }

        this.pushData(layer);
    }

    onSubscribeMysteryBox() {
        let layer = {
            event: 'mystery-box-step-0-2'
        }

        this.pushData(layer);
    }

    onLoginMysteryBox() {
        let layer = {
            event: 'login-box-step-0-3'
        }

        this.pushData(layer);
    }

    onCheckoutMysteryBox() {
        let layer = {
            event: 'checkout-box-step-0-4'
        }

        this.pushData(layer);
    }

    onImpressionEvent(data) {
        this.impressionData.push(data);
    }

    onPageViewEvent(data) {
        this.sendPageView(data);
    }

    sendImpressions() {
        if (this.impressionData.length !== 0) {
            let layer = {
                event: 'eec.impressionView',
                ecommerce: {
                    impressions: this.impressionData
                }
            }

            this.pushData(layer);
            this.impressionData = [];
        }
    }

    sendPageView(uniqueEventId) {
        if (uniqueEventId.length !== 0) {
            let layer = {
                event: 'eec.pageView',
                page_view_unique_event_id: uniqueEventId,
                ecommerce: {}
            }

            this.pushData(layer);
            this.pageViewData = [];
        }
    }

    onImpressionClickEvent(data) {
        let layer = {
            event: 'eec.impressionClick',
            ecommerce: {
                click: {
                    actionField: {
                        list: data.list
                    },
                    products: data.product
                }
            }
        }

        this.pushData(layer);
    }

    onPromotionEvent(data) {
        this.promotionData.push(data);
    }

    sendPromotions() {
        if (this.promotionData.length !== 0) {
            let layer = {
                event: 'eec.promotionView',
                ecommerce: {
                    promoView: {
                        promotions: this.promotionData
                    }
                }
            }

            this.pushData(layer);
            this.promotionData = [];
        }
    }

    onPromotionClickEvent(data) {
        let layer = {
            event: 'eec.promotionClick',
            ecommerce: {
                click: {
                    promotions: this.promotionData
                }
            }
        }

        this.pushData(layer);
    }


    eecMysteryBoxCheckout(data) {
        //step 1
        let layer = [];
        if (data.step == 1) {
            let productsData = [];
            productsData[0] = {
                'name': data.cart.name,
                'id': data.cart.id,
            };

            layer = {
                event: data.event,
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: data.step
                        },
                        products: productsData,
                        checkout_type: data.checkout_type
                    }
                }
            }
        } else {
            layer = {
                event: this.event,
                ecommerce: {
                    checkout: {
                        actionField: {
                            step: this.action,
                            option: this.eventData
                        },
                    }
                }
            }
        }
        this.pushData(layer);
    }

    eecLead() {
        let layer = {
            lead_unique_event_id: this.uniqueEventId,
            event: this.event,
        }

        this.pushData(layer);
    }

    pushData(data) {
        // console.log("pushData", data);
        this.dataLayer.push(data);
    }
}